let key = {};
let IPFS_IMG = " https://naifty.infura-ipfs.io/ipfs/";
var networkVersion = "";
var BNBProvider = "";
let Back_Url = "";
let decimalValues = 1000000000000000000;
var FRONT_URL = "";
var ImG = "";
var EnvName = "demo";
export const ENCODEKEY = process.env.REACT_APP_ENCODEKEY
console.log("config_ENCODEKEY",ENCODEKEY, process.env);
var Front_market_Url = ''

if (EnvName === "local") {
  Back_Url = "http://localhost:5001/v1/admin";
  Front_market_Url = "http://localhost:3000/NovaAdmin";
  var image_url = "hhttp://localhost:5001/token";
  ImG = "http://localhost:5001";
  var Back_Url_Token = "http://localhost:5001/v1";
}



if (EnvName === "demo") {
  Back_Url = "https://backend-nftsolana.maticz.in/v1/admin";
  Front_market_Url = "https://novamarketplaceadmin.maticz.in/";
  var image_url = "https://nova-backend.maticz.in/token";
  ImG = "https://backend-nftsolana.maticz.in";
  var Back_Url_Token = "https://backend-nftsolana.maticz.in/v1";
}

if (EnvName === "production") {

}

key = {
  AdminAPI: `${Back_Url}`,
  Back_Url: `${Back_Url_Token}`,
  ImG: ImG,
  IPFS_IMG: IPFS_IMG,
  AdminAddress: "B7Pkjtk3J8BgWYu6hEzn3J5fibRUnzMzbpMC1BBRiuWD",
  noimg: require("../assets/images/No_image.webp"),
  FRONT_URL: FRONT_URL,
  Front_market_Url:Front_market_Url,
  networkVersion:networkVersion,
  chain : "devnet"
};

export default key;
