import { lazy } from "react";



const Routes = [
    {
        path: "/",
        component: lazy(() => import('../loginpages/Login.js')),
        name: '/'
    },
    {
        path: "/login/admin",
        component: lazy(() => import('../loginpages/Login.js')),
    
    },
    {
        path: "/editarticle",
        component: lazy(() => import('../article/editarticle.js')),
        name: '/admin'
    },
    {
        path: "/article",
        component: lazy(() => import('../article/article.js')),
        name: '/admin'
    },
    {
        path: "/addarticle",
        component: lazy(() => import('../article/addarticle.js')),
        name: '/admin'
    },
    {
        path: "/aboutuser",
        component: lazy(() => import('../Aboutusers/aboutuserlist')),
        name: '/admin'
    },
    {
        path: "/addaboutuser",
        component: lazy(() => import('../Aboutusers/addaboutuser')),
        name: '/admin'
    },
    {
        path: "/editaboutuser",
        component: lazy(() => import('../Aboutusers/editaboutuser')),
        name: '/admin'
    },
    {
        path: "/dashboard",
        component: lazy(() => import('../dashboard/Dashboard.js')),
        name: '/admin'
    },
    {
        path: "/addcategory",
        component: lazy(() => import('../category/addcategory.js')),
        name: '/admin'
    },
    {
        path: "/editcategory",
        component: lazy(() => import('../category/addcategory.js')),
        name: '/admin'
    },
    {
        path: "/categorylist",
        component: lazy(() => import('../category/categorylist.js')),
        name: '/admin'
    },
    {
        path: "/currencylist",
        component: lazy(() => import('../currencyList/currencylist.js')),
        name: '/admin'
    },
    {
        path: "/addtoken",
        component: lazy(() => import('../currencyList/addtoken.js')),
        name: '/admin'
    },
    {
        path: "/editcms/:data",
        component: lazy(() => import('../cmsAndFaq/editcms.js')),
        name: '/admin'
    },
    {
        path: "/cmsandfaq",
        component: lazy(() => import('../cmsAndFaq/cmsbox.js')),
        name: '/admin'
    },
    {
        path: "/addusers",
        component: lazy(() => import('../subscribers/addusers.js')),
        name: '/admin'
    },
    {
        path: "/subscriberslist",
        component: lazy(() => import('../subscribers/subscriberlist.js')),
        name: '/admin'
    },
    {
        path: "/sendmail",
        component: lazy(() => import('../subscribers/sendmail.js')),
        name: '/admin'
    },
    {
        path: "/sociallist",
        component: lazy(() => import('../sociallinks/socaillinks.js')),
        name: '/admin'
    },
    {
        path: "/addsocial",
        component: lazy(() => import('../sociallinks/addsocial.js')),
        name: '/admin'
    },
    {
        path: "/editsociallink",
        component: lazy(() => import('../sociallinks/socaillinks.js')),
        name: '/admin'
    },
    {
        path: "/servicefee",
        component: lazy(() => import('../serviceFeeManagement/listservicefee.js')),
        name: '/admin'
    },
    {
        path: "/userlist",
        component: lazy(() => import('../user/userlist')),
        name: '/admin'
    },
    {
        path:"/userdetail",
        component: lazy(() => import('../user/userlist')),
        name:'/admin'
    },
    {
        path:"/tokenlist",
        component: lazy(() => import('../token/tokenlist')),
        name:'/admin'
    },
    {
        path:"/viewdetail",
        component: lazy(() => import('../token/tokenlist')),
        name:'/admin'
    },
    {
        path: "/faqlist",
        component: lazy(() => import('../faqlist/faqlist.js')),
        name: '/admin'
    },
    {
        path: "/addfaq",
        component: lazy(() => import('../faqlist/addfaq.js')),
        name: '/admin'
    },
    {
        path:"/addfaqcontent",
        component:lazy(()=>import('../faqlist/addfaqcontent')),
        name:'/admin'
    },
    {
        path: "/editfaq",
        component: lazy(() => import('../faqlist/faqlist.js')),
        name: '/admin'
    },
    {
        path: "/editfaqcontent",
        component: lazy(() => import('../faqlist/editfaqcontent'))
    },
    {
        path: "/reportlist",
        component: lazy(() => import('../report/reportlist.js')),
        name: '/admin'
    },
    {
        path: "/viewreporttoken",
        component: lazy(() => import('../report/reportlist.js')),
        name: '/admin'
    },
    {
        path: "/nfttaglist",
        component: lazy(() => import('../nfttag/nfttaglist.js')),
        name: '/admin'
    },
    {
        path: "/editnfttag",
        component: lazy(() => import('../nfttag/nfttaglist.js')),
        name: '/admin'
    },
    {
        path: "/emailtemplatelist",
        component: lazy(()=> import('../emailtemplates/emailtemplatelist')),
        name:'/admin'
    },
    {
        path : "/emailtemplateedit/:slug",
        component : lazy(()=> import('../emailtemplates/emailtemplatelist')),
        name:'/admin'
    },
    {
        path: "/addwhitelist",
        component: lazy(() => import('../Whitelist/addwhitelist.js')),
        name: '/admin'
    },
    {
        path: "/listwhitelist",
        component: lazy(() => import('../Whitelist/listwhitelist.js')),
        name: '/admin'
    },
        //Join us team
        {
            path: "/jointeamList",
            component: lazy(() => import('../JoinusTeam/jointeamList.js')),
            name: '/admin'
        },
        {
            path: "/jointeamAdd",
            component: lazy(() => import('../JoinusTeam/jointeamAdd.js')),
            name: '/admin'
        },
        {
            path: "/jointeamEdit",
            component: lazy(() => import('../JoinusTeam/jointeamEdit.js')),
            name: '/admin'
        },

           //contact us
    {
        path: "/contactusList",
        component: lazy(() => import('../ContactUs/constactusList.js')),
        name: '/admin'
    },
    {
        path: "/contactDetails",
        component: lazy(() => import('../ContactUs/ContactDetails.js')),
        name: '/admin'
    },
       //roadmap
       {
        path: "/listkittyroadmap",
        component: lazy(() => import('../Roadmap/listkittyroadmap.js')),
        name: '/admin'
    },
    {
        path: "/addkittyroadmap",
        component: lazy(() => import('../Roadmap/addkittyroadmap.js')),
        name: '/admin'
    },
    {
        path: "/editkittyroadmap",
        component: lazy(() => import('../Roadmap/editkittyroadmap.js')),
        name: '/admin'
    },
]


export default Routes;
